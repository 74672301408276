import {
  appTimeBundle,
  composeBundlesRaw,
  createDebugBundle,
  createReactorBundle,
  createUrlBundle,
} from 'redux-bundler'
import online from 'redux-bundler/dist/online-bundle'

import cacheBundle from '@common/bundles/cache'
import idle from '@common/bundles/idle'
import snackbar from '@common/bundles/snackbar'
import accountBundle from '@portal/pages/Accounts/accountBundle'
import accountList from '@portal/pages/Accounts/accountListBundle'
import deviceBundle from '@portal/pages/Devices/deviceBundle'
import deviceChartBundle from '@portal/pages/Devices/deviceChartBundle'
import deviceList from '@portal/pages/Devices/deviceListBundle'
import eventModalBundle from '@portal/pages/EventDashboard/EventModal/eventModalBundle'
import eventListBundle from '@portal/pages/EventDashboard/Tabs/Dashboard/eventListBundle'
import eventReviewsListBundle from '@portal/pages/EventDashboard/Tabs/Review/eventReviewsListBundle'
import { scoreboardBundle } from '@portal/pages/Home'
import propertyOutagesListBundle from '@portal/pages/Home/Outages/propertyOutagesListBundle'
import legacyOrganizationListBundle from '@portal/pages/LegacyOrganizations/legacyOrganizationListBundle'
import membershipBundle from '@portal/pages/Memberships/membershipBundle'
import membershipListBundle from '@portal/pages/Memberships/membershipListBundle'
import notificationListBundle from '@portal/pages/Notifications/notificationListBundle'
import { organizationListBundle } from '@portal/pages/Organizations'
import organizationBundle from '@portal/pages/Organizations/organizationBundle'
import organizationGroupListBundle from '@portal/pages/Organizations/organizationGroupListBundle'
import propertyBundle from '@portal/pages/Properties/propertyBundle'
import propertyDetailBundle from '@portal/pages/Properties/propertyDetailBundle'
import propertyEmailListBundle from '@portal/pages/Properties/propertyEmailListBundle'
import propertyList from '@portal/pages/Properties/propertyListBundle'
import installationListBundle from '@portal/pages/Properties/Tabs/Installations/installationListBundle'
import propertyInvoicesListBundle from '@portal/pages/Properties/Tabs/Invoices/propertyInvoicesListBundle'
import propertyReviewsListBundle from '@portal/pages/Properties/Tabs/Reviews/propertyReviewsListBundle'
import propertySmokeProfileListBundle from '@portal/pages/PropertySmokeProfiles/propertySmokeProfileListBundle'
import reservationListBundle from '@portal/pages/Reservations/reservationListBundle'
import roleBundle from '@portal/pages/RolesAndPermissions/roleBundle'
import roleListBundle from '@portal/pages/RolesAndPermissions/roleListBundle'
import scenarioBundle from '@portal/pages/Scenarios/scenarioBundle'
import scenarioListBundle from '@portal/pages/Scenarios/scenarioListBundle'
import reportPreferenceBundle from '@portal/pages/ScheduledReportPreferences/scheduledReportPreferenceBundle'
import reportPreferenceListBundle from '@portal/pages/ScheduledReportPreferences/scheduledReportPreferenceListBundle'
import sensorTestingBundle from '@portal/pages/SensorTesting/sensorTestingBundle'
import shipbobOrderBundle from '@portal/pages/ShipbobOrders/shipbobOrderBundle'
import shipbobOrdersListBundle from '@portal/pages/ShipbobOrders/shipbobOrdersListBundle'
import orgGroupsSelectorBundle from '@portal/pages/SmokeMetrics/orgGroupsSelectorBundle'
import propertyGroupsSelectorBundle from '@portal/pages/SmokeMetrics/propertyGroupsSelectorBundle'
import smokeMetricBundle from '@portal/pages/SmokeMetrics/smokeMetricBundle'
import smokeProfileBundle from '@portal/pages/SmokeProfiles/smokeProfileBundle'
import smokeProfileListBundle from '@portal/pages/SmokeProfiles/smokeProfileListBundle'
import thresholdProfileBundle from '@portal/pages/ThresholdManagement/thresholdProfileBundle'
import thresholdProfileListBundle from '@portal/pages/ThresholdManagement/thresholdProfileListBundle'
import unitBundle from '@portal/pages/Units/unitBundle'
import unitListBundle from '@portal/pages/Units/unitListBundle'
import unitZoneListBundle from '@portal/pages/Units/unitZoneListBundle'
import zoneBundle from '@portal/pages/Units/zoneBundle'
import userBundle from '@portal/pages/Users/userBundle'
import userList from '@portal/pages/Users/userListBundle'

import auditLogListBundle from './auditLogListBundle'
import auth from './auth'
import drawer from './drawer'
import me from './me'
import resetPassword from './resetPassword'
import routes from './routes'
import system from './system'

export default composeBundlesRaw(
  // redux bundler stuff
  appTimeBundle,
  cacheBundle,
  idle,
  drawer,
  createDebugBundle({
    logSelectors: false,
    logState: false,
    actionFilter: (action) => action && action.type !== 'APP_IDLE',
  }),
  createReactorBundle(),
  createUrlBundle(),
  online,
  routes,
  // app bundles
  auth,
  me,
  system,
  snackbar,
  resetPassword,
  auditLogListBundle,
  accountList,
  accountBundle,
  deviceList,
  legacyOrganizationListBundle,
  membershipBundle,
  membershipListBundle,
  notificationListBundle,
  userList,
  deviceBundle,
  deviceChartBundle,
  eventListBundle,
  eventReviewsListBundle,
  eventModalBundle,
  scoreboardBundle,
  propertyOutagesListBundle,
  installationListBundle,
  propertyInvoicesListBundle,
  propertyReviewsListBundle,
  propertySmokeProfileListBundle,
  userBundle,
  propertyList,
  propertyBundle,
  reservationListBundle,
  scenarioBundle,
  scenarioListBundle,
  sensorTestingBundle,
  shipbobOrderBundle,
  shipbobOrdersListBundle,
  orgGroupsSelectorBundle,
  propertyGroupsSelectorBundle,
  reportPreferenceBundle,
  reportPreferenceListBundle,
  roleBundle,
  roleListBundle,
  smokeMetricBundle,
  smokeProfileBundle,
  smokeProfileListBundle,
  thresholdProfileBundle,
  thresholdProfileListBundle,
  organizationListBundle,
  organizationBundle,
  organizationGroupListBundle,
  propertyBundle,
  propertyDetailBundle,
  propertyEmailListBundle,
  unitBundle,
  unitListBundle,
  unitZoneListBundle,
  zoneBundle,
)
